import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'reset/:key', loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule), pathMatch: 'full'
  },
  {
    path: 'transactions',
    loadChildren: () => import('./modules/transactions/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./modules/partners/partners.module').then(m => m.PartnersModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'licenses',
    loadChildren: () => import('./modules/licenses/licenses.module').then(m => m.LicensesModule),
  },
  {
    path: 'receivers',
    loadChildren: () => import('./modules/receivers/receivers.module').then(m => m.ReceiversModule),
  },
  {
    path: 'export-data',
    loadChildren: () => import('./modules/export-data/export-data.module').then(m => m.ExportDataModule),
  },
  {
    path: 'entries',
    loadChildren: () => import('./modules/entries/entries.module').then(m => m.EntriesModule),
  },
  {
    path: 'operational',
    loadChildren: () => import('./modules/operational/operational.module').then(m => m.OperationalModule)
  },
  {
    path: 'configuration',
    loadChildren: () => import('./modules/configuration/configuration.module').then(m => m.ConfigurationModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'integration',
    loadChildren: () => import('./modules/integration/integration.module').then(m => m.SettingsModule)
  },
  {
    path: 'queries',
    loadChildren: () => import('./modules/queries/queries.module').then(m => m.QueriesModule),
  },
  {
    path: 'accounts',
    loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule),
  },
  {
    path: 'integration-banks',
    loadChildren: () => import('./modules/banks/banks.module').then(m => m.BanksModule),
  },
  {
    path: 'operation-banks',
    loadChildren: () => import('./modules/operation-banks/operation-banks.module').then(m => m.OperationBanksModule),
  },
  {
    path: 'heatmap',
    loadChildren: () => import('./modules/heatmap/heatmap.module').then(m => m.HeatmapModule),
  },
  {
    path: 'cloud',
    loadChildren: () => import('./modules/cloud-logs/cloud-logs.module').then(m => m.CloudLogsModule),
  },
  {
    path: 'cnaes',
    loadChildren: () => import('./modules/cnaes/cnaes.module').then(m => m.CnaesModule),
  },
  {
    path: '*', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: 'home', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
